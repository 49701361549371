<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>{{ switchTitlePage($route.name) }}</h3>
    </div>
    <div class="border pt-1 px-1 rounded-lg shadow-sm mb-1">
      <b-row>
        <b-col cols="3">
          <form-input label="" v-model="filter" placeholder="  ابحث هنا عن اسم المستخدم..." class="w-50" />
        </b-col>
        <b-col cols="1" ></b-col>
        <b-col cols="8">
          <div class="d-flex justify-content-end mb-1">
            <b-button
              v-for="(item, i) in filterDate"
              :key="i"
              size="md"
              variant="outline-success"
              pill
              class="mx-1"
              @click="
                utils.status = item.status;
                $refs.estatesTable.refreshTable();
              "
              :class="utils.status == item.status ? 'bg-light-success' : ''"
              >{{ item.label }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
    <data-table :utils="utils" ref="estatesTable" :fields="fields" :ep="switchEndPoint($route.name)">
      <template #cell(beneficiarys)="row"> 
        {{ `${row.item.beneficiary.first_name} ${row.item.beneficiary.last_name}`}}
      </template>
      <template #cell(actions)="row">
        <div>
          <b-button
            variant="flat-info"
            title="عرض المزيد من المعلومات"
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class=""
            v-b-tooltip.hover.v-info.top
            @click="row.toggleDetails"
            v-b-toggle.collapse-1
          >
            المزيد
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </b-button>

            <b-button
            v-if="row.item.status=='N'"
            v-can="{ slug: $route.meta.slug, action: 'O' }"
            variant="flat-success "
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class=""
            @click="completed({status:'C', ep: switchCompleted($route.name, row.item.id)  }).then(()=>$refs.estatesTable.refreshTable())"
          >
            إكمال الحجز
          </b-button>
        </div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <b-row>
            <b-col cols="4">
              <icon-media
                icon="ClockIcon"
                keyText="توقيت البداية"
                :text="$moment(row.item.from_time,['h:mm']).locale('ar').format('hh:mm A')"
                class="mb-1"
              ></icon-media>
              <icon-media
                icon="ClockIcon"
                keyText="توقيت النهاية"
                :text="$moment(row.item.to_time,['h:mm']).locale('ar').format('hh:mm A')"
                class="mb-1"
              ></icon-media>
              <icon-media
                icon="CalendarIcon"
                keyText="تاريخ البداية"
                :text="row.item.from_date"
                class="mb-1"
              ></icon-media>
              <icon-media
                icon="CalendarIcon"
                keyText="تاريخ النهاية"
                :text="row.item.to_date"
                class="mb-1"
              ></icon-media>

              <icon-media
                icon="InfoIcon"
                keyText="عدد الحضور"
                :text="row.item.number_contributor"
                class="mb-1"
              ></icon-media>
              <icon-media
                icon="InfoIcon"
                keyText="عدد أيام الحجز"
                :text="row.item.number_reservation_days"
                class="mb-1"
              ></icon-media>
              <icon-media
                icon="ClockIcon"
                keyText="عدد الساعات الجلسة"
                :text="row.item.session_hours"
                class="mb-1"
              ></icon-media>
              
            </b-col>
            <b-col cols="5" >
        
              <h6 class="text-primary">التفاصيل</h6>
              <p>
                {{ row.item.description }}
              </p>
              <hr />
              <div>
                <h6 class="text-primary">التجهيزات المطلوبة</h6>
                <p>
                  {{ row.item.assets }}
                </p>
              </div>
            </b-col>
            <b-col cols="3">
              <h6>الأيام</h6>
              <b-badge
                v-for="(item, i) in row.item.working_days"
                :key="i"
                class="mr-1"
                variant="light-primary"
                >{{ $moment().day(item).locale("ar").format("dddd") }}</b-badge
              >
              
            </b-col>
          </b-row>
        </b-collapse>
      </template>
    </data-table>
  </b-card>
</template>
  
  <script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BButton,
  BCollapse,
  VBToggle,
  VBTooltip,
  BModal,
  BBadge,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import { debounce } from "lodash";
import Ripple from "vue-ripple-directive";
import IconMedia from "@/components/icon-media.vue";
import { mapActions } from "vuex";
import mixinsPage from "./mixins";

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,    
    VBTooltip,
    BCollapse,
  VBToggle,
    BModal,
    DataTable,
    IconMedia,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mixins:[mixinsPage],
  data() {
    return {
      activModel: false,
      filter: "",
      fields: [
        { key: "title", label: "الاسم" ,sortable:true },
        { key: "beneficiarys", label: "اسم المستخدم" },
        { key: "phone_number", label: "رقم التواصل" },
        { key: "destination", label: "الجهة" },
        { key: "actions", label: "" },
      ],
      filterDate: [
        { id: 1, label: "تحت الطلب", status: "N" },
        { id: 1, label: "محجوزة", status: "C" },

      ],
      utils: {
        status: "N",
      },
    };
  },
  methods:{
    ...mapActions("manageCenter/bookings/halls", ["completed"]),
  },
  watch: {
    procedure_id: debounce(function (value) {
      this.$refs.refprocedure.refreshOptions({
        procedure_id: this.procedure_id,
      });
    }, 700),
  },
};
</script>